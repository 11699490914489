/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre",
    a: "a"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Spacing scale"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for margin, padding, space between, all at once, use the ", _jsx(_components.code, {
        children: "theme.space"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n  export const theme = {\n    space: {\n+     sm: '8px',\n+     md: '16px',\n+     lg: '24px',\n+     xl: '48px',\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Learn more about customizing the default theme in the ", _jsx(_components.a, {
        href: "@TODO-link",
        children: "theme customization documentation"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "space"
      }), " is already defined in default theme:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const defaultTheme = {\n  // ...\n  space: {\n    0.5: '0.125rem',\n    1: '0.25rem',\n    1.5: '0.375rem',\n    2: '0.5rem',\n    2.5: '0.625rem',\n    3: '0.75rem',\n    3.5: '0.875rem',\n    4: '1rem',\n    5: '1.25rem',\n    6: '1.5rem',\n    7: '1.75rem',\n    8: '2rem',\n    9: '2.25rem',\n    10: '2.5rem',\n    11: '2.75rem',\n    12: '3rem',\n    14: '3.5rem',\n    16: '4rem',\n    20: '5rem',\n    24: '6rem',\n    28: '7rem',\n    32: '8rem',\n    36: '9rem',\n    40: '10rem',\n    44: '11rem',\n    48: '12rem',\n    52: '13rem',\n    56: '14rem',\n    60: '15rem',\n    64: '16rem',\n    72: '18rem',\n    80: '20rem',\n    96: '24rem',\n  },\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
